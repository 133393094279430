// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-template-js": () => import("/opt/build/repo/src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("/opt/build/repo/src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-current-js": () => import("/opt/build/repo/src/pages/current.js" /* webpackChunkName: "component---src-pages-current-js" */),
  "component---src-pages-featured-story-js": () => import("/opt/build/repo/src/pages/featured-story.js" /* webpackChunkName: "component---src-pages-featured-story-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-submit-js": () => import("/opt/build/repo/src/pages/submit.js" /* webpackChunkName: "component---src-pages-submit-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

